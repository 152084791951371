// Colors
$color-light: #ffffff;
$color-gray-light: #edf0f2;
$color-gray: #8a9dad;
$color-gray-dark: #303030;
$color-dark: #000000;
$color-dark-light: #252e35;
$color-primary-light: #f0f7ff;
$color-primary-lighter: #2596be;
$color-primary-bright: #2990ff;
$color-primary-brighter: #7abaff;
$color-primary: #007bff;
$color-primary-dark: #0060c7;
$color-primary-darker: #00458f;
$color-secondary: #0f8697;
$color-secondary-lighter: #48bd77;
$color-secondary-neutral: #8a9dad;
$color-secondary-neutral-lighter: #f6f8f9;
$color-warning: #f2b75f;
$color-error: #f25f64;
$color-activated: #3772ff;
$color-separator: #dfe5eb;
$color-secondary-blue: #e0efff;
$color-cream: #ffcb7d;
$color-sun: #fbdd40;
$color-red: #ff4141;
$color-emerald: #3ea366;
$color-mint: #64d190;
$color-future: #a3cfff;
$color-aligner-active: #80e8a9;
$color-aligner-passive: #a8b6c2;
$color-no-movement: #dfe5eb;
$color-grey-background: #707070;
$color-space: #f2b75f;

// Primary
$color-primary-200: #cce5ff;

// Secondary
$color-neutral: #647b8f;
$color-neutral-100: #edf0f2;
$color-neutral-300: #c9d2d9;
$color-danger: #f58489;

// Spacing
$spacing-xxs: 0.2rem;
$spacing-xs: 0.5rem;
$spacing-s: 1rem;
$spacing-sm: 1.5rem;
$spacing-m: 2rem;
$spacing-l: 2.5rem;
$spacing-xl: 3rem;
$spacing-xxl: 3.5rem;
$spacing-xxxl: 4rem;

// FontFamily
$ff-poppins-light: 'poppinsLight';
$ff-poppins-regular: 'poppinsRegular';
$ff-poppins-semibold: 'poppinsSemiBold';
$ff-poppins-bold: 'poppinsBold';

// FontSizes
$font-size-xxs: 0.57rem; // 9px
$font-size-xs: 0.6875rem; // 11px
$font-size-s: 0.8125rem; // 13px
$font-size-sm: 0.875rem; // 14px
$font-size-m: 1rem; // 16px
$font-size-l: 1.25rem; // 20px

// FontWeight
$font-weight-regular: 400;
$font-weight-bold: 600;

// Z-index
$z-index-s: 1;
$z-index-m: 10;
$z-index-l: 20;
$z-index-xl: 50;
$z-index-xxl: 100;

// Time
$ani-fast: 0.15s;
$ani-normal: 0.35s;
$ani-slow: 0.5s;

// Override boostrap variables
$modal-inner-padding: 1.5rem;
$secondary: #252e35;

// Global variables
$scrollbar-width: 10px;
$ev-panel-width: 1.18rem;
$ev-panel-space: 3px;
$ev-panel-piece-h: 1.3rem;

// Media sizes
$media-s: 768px;
$media-ms: 988px; // Size of 3D vertical canvas while comparing
$media-m: 1024px;
$media-l: 1280px;
$media-xl: 1440px;
$media-xxl: 1680px;
$media-2k: 2048px;
$media-4k: 3840px;

.tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: $spacing-s;
  overflow: hidden;

  &-nav.nav {
    display: flex;
    padding: 2px;
    align-items: center;
    gap: 2px;
    align-self: stretch;
    border-radius: 4px;
    background: $color-separator;
  }

  &-item.nav-item {
    display: flex;
    width: 100%;
    height: 100%;
    gap: $spacing-xs;
    flex: 1 0 0;
    font-size: $font-size-xs;
    font-family: $ff-poppins-bold;
    margin: 0;
    border: 0;
    border-radius: 2px;
    background-color: transparent;
  }

  &-tab.nav-link {
    display: flex;
    padding: 4px $spacing-xs;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: var(--bs-body-color);
    letter-spacing: 0.3px;
    text-wrap: nowrap;

    &:hover {
      color: $color-primary;
    }

    &.active {
      background-color: $color-light;
      color: $color-primary;

      &:hover {
        background-color: $color-primary-light;
      }
    }
  }

  &-content.tab-content {
    flex: 1 0 0;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &-panel.tab-pane {
    height: 100%;
  }
}
