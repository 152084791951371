// Define global styles for the application,
// usually html and body content.

html,
body {
  font-family: poppinsLight;
  font-size: 16px;
  width: 100%;
  background: $color-primary-light;
  overflow: hidden;
}

.app {
  canvas {
    background: $color-primary-light !important;
  }
}

// Tooltip

.clickable {
  cursor: pointer;

  path {
    transition: all $ani-normal;
  }

  &:hover {
    path {
      fill: $color-primary;
    }
  }
}

.tooltip {
  box-shadow: 0 6px 8px 0 rgb(26 26 26 / 25%);

  &.type-dark {
    background: $color-dark-light;
    padding: 0.25rem 0.75rem;
    border: 0;
    z-index: $z-index-xxl;
    opacity: 1 !important;

    &.place-bottom:after {
      border-bottom-color: $color-dark-light;
    }

    &.place-top:after {
      border-top-color: $color-dark-light;
    }

    &.place-left:after {
      border-left-color: $color-dark-light;
    }

    &.place-right:after {
      border-right-color: $color-dark-light;
    }
  }
}

// Button actions
.btn-primary {
  @include button-variant($color-primary, $color-primary, $color-light);
}

.btn-outline-primary {
  @include button-outline-variant($color-primary, $color-light, lighten($color-primary, 5%), $color-primary);
}

.btn {
  &-action {
    border-radius: 0;

    &:hover {
      background: $color-primary-light;
    }
  }
}

// Modal
.modal {
  &-opened {
    display: block;
    background: rgb(0 0 0 / 50%);
  }

  &-dialog {
    @media (min-width: 576px) {
      min-width: 38rem;
    }

    &-centered {
      margin-top: -1%;
    }

    .btn {
      font-family: poppinsRegular;
      font-size: $font-size-sm;
    }
  }

  &-footer {
    border-top: 0;
    padding-top: 0;
  }
}

// icons
.icon {
  &.rotate {
    &-90 {
      transform: rotate(90deg);
    }
  }
}
